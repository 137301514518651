.table-container {
    overflow: auto;
    max-height: 850px;

    /* background-color: #e6e6e6; */
  }
  
  .table-container thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .table-size{
    zoom: .43;
  }
  /* @media (min-width: 1484px){
     .table-size{
      zoom: .43;
     }
  } */
  
  .table-container td, thead {
    font-weight:550;
    font-size:x-large;
  }
  .table-container td{
    cursor: pointer;
  
  }
  .table span{
    color:white;
    zoom: 1.4;
  }
  .table button{
    margin-left: 10px;
  }
  .table-container tbody tr:hover{
    background-color: rgb(230, 240, 249);
  }

.info-labels{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}
.label{
  padding: 5px;
  border-radius: 5px;
  font-size: x-small;
  font-weight: 550;
}
.datePicker-td{
  position: relative;
}
.datePicker{
position: absolute;
}
.custom-datepicker-popper{
  font-size: 34px;
  width: 400px;
  margin-left: 90px;
}