body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #eff0f3 !important;
  color: #666666 !important;
  font-family: 'Montserrat', sans-serif !important;
}


.material-icons-outlined{
  vertical-align: middle;
  line-height: 1px;
}
.grid-container{
  display: grid;
  grid-template-columns: 250px 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas: 
      "sidebar header header header"
      "sidebar main main main";
  height: 100vh;
}
/* header  */
.header{
  grid-area: header;
  height: 70px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;
  box-shadow: 0 6px 7px -4px rgb(0, 0 , 0, 0.2);
}
.header-user{
  grid-area: header;
  height: 70px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 30px 0 30px;
  box-shadow: 0 6px 7px -4px rgb(0, 0 , 0, 0.2);
}

.menu-icon{
  display: none;
}
.header-left{
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.header-right{
  display: flex;
  flex-direction: row;
  gap: 15px;
}

/* sidebar  */
#sidebar{
  grid-area: sidebar;

  hyphens: 100vh;
  background-color: #21232d;
  color:#9799ab;
}
.sidebar-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-bottom: 30px;
}
.sidebar-title > span{
  display: none;
}
.sidebar-brand{
  margin: 15px;
  font-size: 20px;
  font-weight: 700;
}
.sidebar-list{
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-top: 15px;
  list-style-type: none;
}
.sidebar-list-item{
  padding: 20px;
  color: inherit;
  text-decoration: none;
}
.sidebar-list-item:hover{
  background-color: rgb(255, 255, 255, 0.2);
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

/* main  */
.main-container{
 grid-area:main;
 overflow: auto;
 padding: 20px 20px;
}

@media (max-width: 1411px ){
  .main-container{
    zoom: .90
  }
}
@media (max-width: 1296px ){
  .main-container{
    zoom: .80
  }
}
@media (max-width: 1184px ){
  .main-container{
    zoom: .70
  }
}
@media (max-width: 1068px ){
  .main-container{
    zoom: .60
  }
}
@media (max-width: 992px ){
  .main-container{
    zoom: .8
  }
}
@media (max-width: 992px ){
  .main-container{
    zoom: .7
  }
}
.main-title{
  display: flex;
  justify-content: space-between;
}
.main-title > p{
  font-size: 20px;
  font-weight: 600;
}
.main-cards{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  margin: 20px 0;
}
.card{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px;
  background-color: #ffffff;
  box-sizing: border-box;
  border: 1px solid #d2d2d3;
  border-radius: 5px;
  box-shadow: 0 6px 7px -4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: 0.3s;
}

.card:hover{
  -webkit-box-shadow: 5px 10px 5px 1px rgba(0,0,0,0.39);
  -moz-box-shadow: 5px 10px 5px 1px rgba(0,0,0,0.39);
  box-shadow: 5px 10px 5px 1px rgba(0,0,0,0.39);
}

.card:first-child{
  border-left: 7px solid #f5b74f;
}
.card:nth-child(2){
  border-left: 7px solid #246dec;
}
.card:nth-child(3){
  border-left: 7px solid #367952;
}
.card:nth-child(3) h5{
  color: #367952;
}
.card:nth-child(4){
  border-left: 7px solid #cc3c43;
}

.text-blue{
  color: #246dec;
}
.text-orange{
  color: #f5b74f;
}
.text-green{
  color: #367952;
}
.text-red{
  color: #cc3c43;
}

.card > span{
  font-size: 20px;
  font-weight: 600;
}

.card-inner{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-inner > p{
  font-size: 18px;
}
.card-inner > span{
  font-size: 35px;
}

.charts{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

}
.charts-card{
  background-color: #ffffff;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  border: 1px solid #d2ded3;
  border-radius: 5px;
  box-shadow:0 6px 7px -4px rgb(0, 0 , 0, 0.2);

}
.users-charts{
  display: flex;
  align-items: center;
  justify-content: center;
}
.chart-title{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 600;
}

/* inputs */
.search-reset_inputs{
  display: flex;
}
.addColab-input{
  margin-right: 5px;
  margin-left: 2px;
}

/* dashboard visuals */
.selected-label{
  background-color: rgb(255, 255, 255, 0.2);
}
.anchor-link{
  text-decoration: none;
  color: #9799ab ;
}
.table-purple{
  background-color: rgb(187, 175, 223) !important;
}
/* media queries */

@media screen and (max-width: 992px ){
 .grid-container{
  grid-template-columns: 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas: 
  "header"
  "main";
 }

 #sidebar{
  display: none;
 }

 .menu-icon{
  display: inline;
 }

 .sidebar-title > span{
  display: inline;
}
}
@media screen and (max-width: 768px ){
 .main-cards{
  grid-template-columns: 1fr;
  gap: 10px;
  margin-bottom: 0;
 }

 .charts{
  grid-template-columns: 1fr;
  margin-top: 30px;
 }
}
@media screen and (max-width: 576px ){
 .header-left{
  display: none;
 }
}

.active{
background-color: #246dec;
}
.navLink{
  margin: 10px;
}
table thead, table span, table button{
  background-color: #302b63;
  color: white;
}
table th, td{
  text-align: center;
  white-space: nowrap;
}
.titles{
  font-weight: 700;
}